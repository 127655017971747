import { createStore } from 'vuex'

export default createStore({
  state: {
    // base_url: 'https://patient-srv.rebeccamed.com',
    base_url: 'https://patient-srv.rebeccamed.com',
    count: 0,
  },
  mutations: {
    log (state) {
      state.count++
    }
  },
  actions: {

  },
  modules: {

  }
})

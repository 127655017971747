<template>
  <div class="about">
    <van-nav-bar
        title="添加诊断"
        @click-left="onClickLeft"
    >
      <template #left>
        <van-icon name="arrow-left" color="black"  size="20px"/>
      </template>
    </van-nav-bar>
    <div style="padding: 10px;margin:30px 10px 10px 10px">
      <div class="card">
        <div style="color: #828282;font-size: 16px;">诊断内容</div>
        <div>
          <van-field
              v-model="diagnosis_content"
              rows="8"
              autosize
              type="textarea"
              placeholder="请输入.."
          />
        </div>
      </div>
    </div>
    <div style="margin: 20px">

      <van-button block  color="#2A9EF8" style="margin-top:20px;box-shadow: 0px 10px 20px rgba(254, 84, 84, 0.2);border-radius: 10px;height: 50px" @click="onSubmit" :loading="uploading">
        <div style="font-size: 20px">提交</div>
      </van-button>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import router from "@/router";
import { areaList } from '@vant/area-data';


export default {
  name: 'DiagnosisAdd',
  setup() {
    return { areaList };
  },
  data() {
    return {
      diagnosis_content:"",
      uploading:false,
    };
  },
  methods: {
    onClickLeft:function (){
      router.go(-1)
    },
    onSubmit() {
      //检测数据
      let app = this;
      if (this.diagnosis_content == "") {
        this.$toast('请输入诊断内容');
        return 1;
      }
      this.uploading = true;
      this.axios({
        method: 'post',
        url:  this.$store.state.base_url+'/api/oab_client/diagnosis/add',
        data:{diagnosis_content:  app.diagnosis_content}
      }).then(function (res) {
        if (res.data.code == 200) {
          app.uploading = false
        }
        app.$toast("添加成功");
        const timer = setInterval(() => {
          clearInterval(timer);
          router.go(-1);
        }, 2000);

      }).catch(function (error) {

      })
    }
  }

}
</script>


<style scoped>
.van-field{padding: 5px 0px !important}
</style>

import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vant from 'vant'
import 'vant/lib/index.css'

import axios from 'axios'

import VueAxios from 'vue-axios'


//tool
Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

Date.prototype.setSimpleDate = function (year, month, day) {
    this.setFullYear(year)
    this.setMonth(month - 1)
    this.setDate(day)
}

//请求添加token
axios.interceptors.request.use(config => {
        if (localStorage.getItem('Authorization')) {
            config.headers.Authorization = "bearer" + localStorage.getItem('Authorization');
        }
        return config;
    },
    error => {
        console.log(error)
        return Promise.reject(error);
    });

axios.interceptors.response.use(data=> {
    return Promise.resolve(data);
}, err=> {
    if (err.response.status == 401) {
        router.replace({
            path: 'login',
            query: {redirect: router.currentRoute.fullPath}
        });
    };
    return Promise.resolve(err);
})









const app = createApp(App).use(store).use(router).use(Vant).use(VueAxios, axios);


app.mount('#app')
<template>
  <div class="about">

    <van-nav-bar
        title="患者列表"
        @click-left="onClickLeft"
        @click-right="onClickRight"
    >
      <template #left>
        <van-icon name="arrow-left" color="black"  size="20px"/>
      </template>
      <template #right>
        <van-icon name="plus" color="black"  size="20px"/>
      </template>
    </van-nav-bar>



    <van-dropdown-menu>
      <van-dropdown-item title="医院" v-model="value" ref="dropdown_menu_h">
        <div>
          <van-tree-select
              v-model:active-id="activeIds"
              v-model:main-active-index="activeIndex"
              :items="h_items"
          />
        </div>

        <div style="padding: 10px">
          <van-row  gutter="10">
            <van-col span="12">
              <van-button block type="default" @click="reloadOrgData(1)">重置</van-button>
            </van-col>
            <van-col span="12">
              <van-button block type="primary" @click="reloadData">查询</van-button>
            </van-col>
          </van-row>
        </div>

      </van-dropdown-item>



      <van-dropdown-item title="性别" ref="dropdown_menu_s">
        <div style="padding: 30px;display: flex;align-items: center;justify-content: center">
          <van-checkbox-group v-model="sexy_result" direction="horizontal">
            <van-checkbox name="男性">男性</van-checkbox>
            <van-checkbox name="女性">女性</van-checkbox>
          </van-checkbox-group>
        </div>

        <div style="padding: 10px">
          <van-row  gutter="10">
            <!--            <van-col span="0">-->
            <!--              <van-button block type="default" @click="reloadOrgData()">重置</van-button>-->
            <!--            </van-col>-->
            <van-col span="24">
              <van-button block type="primary" @click="reloadData">查询</van-button>
            </van-col>
          </van-row>
        </div>
      </van-dropdown-item>


      <van-dropdown-item title="年龄" ref="dropdown_menu_a">
        <div style="padding:30px">
          <van-slider v-model="age_range" range  @input="onAgeSelect" />
        </div>
        <div style="text-align: center">
          {{ age_range[0] }}岁 ～ {{ age_range[1]}}岁
        </div>

        <div style="padding: 10px">
          <van-row  gutter="10">
            <van-col span="12">
              <van-button block type="default" @click="reloadOrgData()">重置</van-button>
            </van-col>
            <van-col span="12">
              <van-button block type="primary" @click="reloadData">查询</van-button>
            </van-col>
          </van-row>
        </div>
      </van-dropdown-item>

      <van-dropdown-item title="诊断" ref="dropdown_menu_d">


        <van-checkbox-group v-model="select_zd">
          <van-cell-group>
            <van-cell
                v-for="(item, index) in zd_list"
                clickable
                :key="item.id"
                :title="`${item.content}`"
                @click="toggle(index)"
            >
              <template #right-icon>
                <van-checkbox
                    :name="item"
                    :ref="el => checkboxRefs[index] = el"
                    @click.stop
                />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>

        <div style="padding: 10px">
          <van-row  gutter="10">
            <van-col span="12">
              <van-button block type="default" @click="reloadOrgData(4)">重置</van-button>
            </van-col>
            <van-col span="12">
              <van-button block type="primary" @click="reloadData">查询</van-button>
            </van-col>
          </van-row>
        </div>
      </van-dropdown-item>

      <van-dropdown-item title="次数" ref="dropdown_menu_s">
        <div style="padding: 30px;display: flex;align-items: center;justify-content: center">
          <van-radio-group v-model="treatments_count" direction="horizontal">
            <van-radio name="0">0次</van-radio>
            <van-radio name="1">1次</van-radio>
            <van-radio name="2">2次</van-radio>
          </van-radio-group>
        </div>
        <div style="font-size:12px;text-align: center;padding: 20px 0px">不选择时查询全部用户</div>


        <div style="padding: 10px">
          <van-row  gutter="10">
            <van-col span="12">
              <van-button block type="default" @click="reloadOrgData(11)">重置</van-button>
            </van-col>
            <van-col span="12">
              <van-button block type="primary" @click="reloadData">查询</van-button>
            </van-col>
          </van-row>
        </div>
      </van-dropdown-item>


    </van-dropdown-menu>



    <div v-if="!loading">
      <div v-if="patients_list.length == 0" style="text-align: center;padding-top: 20%;font-weight: 800;font-size: 40px;color: rgba(0,0,0,0.1)">
        暂无数据
      </div>
      <div v-else style="margin-top: 20px">
        <div class="card" style="padding: 10px;background: #FFFFFF;border-bottom: 1px solid #efefef;margin:20px" v-for="item in patients_list" @click="toPatientsReadOnly(item.id)">
          <div style="position: relative">
            <div style="font-size: 14px;color: #3C3C3C;display: flex;align-items: center;position: relative">
              <div>
                <van-icon name="https://rebeccamed.oss-cn-hangzhou.aliyuncs.com/resources/icons-user-list-u.svg" size="12px" />
              </div>
              <div style="width: 60px;margin-left: 5px">{{ item.name }}</div>
              <div style="width: 1px;height:5px;background: #828282"></div>
              <div style="width: 60px;text-align: center">{{ item.sexy }}</div>
              <div style="width: 1px;height:5px;background: #828282"></div>
              <div style="width: 60px;text-align: center">{{ item.bri }}岁</div>
              <div style="width: 1px;height:5px;background: #828282"></div>
              <div style="width: 200px;text-align: left;margin-left: 10px">治疗次数：{{ item.treat_count }} </div>
              <div style="text-align: center;border-top-right-radius: 10px;border-bottom-left-radius: 10px;background: rgba(0,0,0,0.1);font-size: 12px;position: absolute;top: -10px;right: -10px;width: 60px;padding: 5px" v-if="item.come_form == 1">
                微信用户
              </div>
            </div>

            <div style="font-size: 12px;color: #3C3C3C;display: flex;align-items: center;margin-top: 10px">
              <div>
                <van-icon name="https://rebeccamed.oss-cn-hangzhou.aliyuncs.com/resources/icons-user-list-t.svg" size="12px" />
              </div>
              <div style="margin-left: 5px">{{ item.tel }}</div>
            </div>

            <div style="font-size: 12px;color: #3C3C3C;display: flex;align-items: center;margin-top: 10px">
              <div>
                <van-icon name="https://rebeccamed.oss-cn-hangzhou.aliyuncs.com/resources/icons-user-list-h.svg" size="12px" />
              </div>
              <div style="margin-left: 5px">{{ item.hospital.name }}</div>
              <div style="padding: 2px 10px;border-radius: 20px;background: #8AC4D0;color: #FFFFFF;margin-left: 10px">{{ item.hospital.level }}</div>
            </div>

            <div style="position: absolute;top: 0px;right: 0px;bottom: 0px;width: 40px;display: flex;align-items: center;justify-content: flex-end">
              <van-icon name="https://rebeccamed.oss-cn-hangzhou.aliyuncs.com/resources/icons_list_edit.svg" size="15px" @click.stop="toPatients(item.id)"/>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div v-else style="padding-top: 20%;text-align: center">
      <van-loading size="40px" />
    </div>




  </div>
</template>

<script>
// @ is an alias to /src
import router from "@/router";
import { ref, onBeforeUpdate } from 'vue';


export default {
  name: 'PatientList',
  setup() {
    const checked = ref([]);
    const checkboxRefs = ref([]);
    const toggle = (index) => {
      checkboxRefs.value[index].toggle();
    };
    onBeforeUpdate(() => {
      checkboxRefs.value = [];
    });

    //医院
    let activeId = ref([1, 2]);
    let activeIndex = ref(0);
    let h_items = [];
    return {
      toggle,
      checked,
      checkboxRefs,
      //医院
      h_items,
      activeId,
      activeIndex,
    };
  },
  mounted() {
    let app = this;
    this.axios({
      method: 'post',
      url:  this.$store.state.base_url+'/api/oab_client/patient/list',
    }).then(function (res) {
      app.patients_list = res.data.patients;
      app.loading = false;
    }).catch(function (error) {
    });
    //获取搜索检索
    this.axios({
      method: 'post',
      url:  this.$store.state.base_url+'/api/oab_client/search_index',
    }).then(function (res) {
      app.zd_list = res.data.diagnosis_index;
      let h = res.data.hospital_index;
      Object.keys(h).forEach(function (item, index){
        var sub_list = [];
        h[item].forEach(function (ite){
          var sub_obj = { text: ite.name, id: ite.id };
          sub_list.push(sub_obj);
        });
        let obj = {
          text: item,
          children: sub_list,
        }
        app.h_items.push(obj)
      })
      app.loading = false;
    }).catch(function (error) {
    })
  },
  components: {
  },
  data() {
    return {
      loading:true,
      patients_list:[],
      zd_list:[],
      select_zd:[],
      treatments_count:null,
      value1:'',
      value2:'',
      option1:[1,2],
      option2:[1,2],
      activeIds:[],
      age_range:[0,120],
      sexy_result:["男性","女性"]
    };
  },
  methods: {
    onClickLeft:function (){
      router.go(-1)
    },
    onClickRight:function (){
      router.push({ name: 'patient_add'})
    },
    toPatients:function (e){
      console.log(e)
      this.$router.push({path: '/patient/info/'+ e});

      // router.push({ name: 'patient_info', params: { id: e }})
    },
    toPatientsReadOnly:function (e){
      console.log(e)
      this.$router.push({path: '/patient/infoReadOnly/'+ e});
    },
    reloadOrgData:function (e) {
      if (e == 1) {
        this.activeIds = [];
      } else if(e == 4) {
        this.select_zd = [];
      } else if(e == 11) {
        this.treatments_count = null;
      }
    },
    reloadData:function () {

      this.$refs.dropdown_menu_h.toggle(false);
      this.$refs.dropdown_menu_s.toggle(false);
      this.$refs.dropdown_menu_a.toggle(false);
      this.$refs.dropdown_menu_d.toggle(false);

      let app = this;
      this.loading = true;
      this.axios({
        method: 'post',
        data:{
          select_zd:this.select_zd,
          sexy_result:this.sexy_result,
          hospital_list:this.activeIds,
          age_range:this.age_range,
          treatments_count:this.treatments_count
        },
        url:  this.$store.state.base_url+'/api/oab_client/patient/list',
      }).then(function (res) {
        console.log("=====")
        console.log(res);
        console.log("=====")
        app.patients_list = res.data.patients;
        app.loading = false;
      }).catch(function (error) {
      })
    },
    onAgeSelect:function (e){
      console.log(e)
    }
  }

}
</script>

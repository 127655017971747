import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Settings from "@/views/Settings";
import Login from "@/views/Login";
import PatientList from "@/views/patient/List";
import AddPatient from "@/views/patient/Add";
import PatientInfo from "@/views/patient/Info";
import PatientInfoReadOnly from "@/views/patient/ReadOnlyInfo"
import HospitalInfo from "@/views/hospital/Info";
import HospitalAdd from "@/views/hospital/Add";
import HospitalList from "@/views/hospital/List";
import DiagnosisList from "@/views/diagnosis/List";
import DiagnosisAdd from "@/views/diagnosis/Add";
import DiagnosisInfo from "@/views/diagnosis/Info";

const routes = [{
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/setting',
        name: 'Settings',
        component: Settings
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/patient/add',
        name: 'patient_add',
        component: AddPatient
    },
    {
        path: '/patient/list',
        name: 'patient_list',
        component: PatientList,
        meta: {keepAlive: true}
    },
    {
        path: '/patient/info/:id',
        name: 'patient_info',
        component: PatientInfo,
        meta: {keepAlive: true}
    },
    {
        path: '/patient/infoReadOnly/:id',
        name: 'patient_infoReadOnly',
        component: PatientInfoReadOnly,
    },
    {
        path: '/patient/edit',
        name: 'patient_edit',
        component: AddPatient
    },
    {
        path: '/patient/uri/list/:id',
        name: 'uri_list',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/patient/UriList'),
    },
    {
        path: '/patient/uri/log/:id',
        name: 'uri_log',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/patient/UriLog'),
    },
    {
        path: '/patient/uri/scan',
        name: 'uri_log_scan',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/patient/UriLogByScan'),
    },
    {
        path: '/hospital/list',
        name: 'hospital_list',
        component: HospitalList
    },
    {
        path: '/hospital/info',
        name: 'hospital_info',
        component: HospitalInfo
    },
    {
        path: '/hospital/add',
        name: 'hospital_add',
        component: HospitalAdd
    },
    {
        path: '/diagnosis/list',
        name: 'diagnosis_list',
        component: DiagnosisList
    },
    {
        path: '/diagnosis/info',
        name: 'diagnosis_info',
        component: DiagnosisInfo
    },
    {
        path: '/diagnosis/add',
        name: 'diagnosis_add',
        component: DiagnosisAdd
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {

    if (to.path === '/login' || to.path === '/patient/uri/list') {
        next();
    } else {
        let token = localStorage.getItem('Authorization');
        if (token === 'null' || token === '') {
            next('/login');
        } else {
            next();
        }
    }


});

export default router
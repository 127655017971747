<template>
  <div class="about">

    <van-nav-bar
        title="医院列表"
        @click-left="onClickLeft"
        @click-right="onClickRight"
    >
      <template #left>
        <van-icon name="https://rebeccamed.oss-cn-hangzhou.aliyuncs.com/resources/icons_left_arrow.svg" size="18" />
      </template>
      <template #right>
        <van-icon name="plus" color="black"  size="20px"/>
      </template>
    </van-nav-bar>

    <div>
      <div v-if="loading" style="text-align: center;padding-top: 20%;font-weight: 800;font-size: 40px;color: rgba(0,0,0,0.1)">
        <van-loading size="40px" />
      </div>
      <div v-else-if="hospital_list.length == 0" style="text-align: center;padding-top: 20%;font-weight: 800;font-size: 40px;color: rgba(0,0,0,0.1)">
        暂无数据
      </div>
      <div v-else style="background: #FFFFFF">
        <div v-for="item in hospital_list" @click="toHospital(item.id)">
          <div style="position: relative;height:60px;display: flex;align-items: center;background: #FFFFFF;border-bottom: 1px solid #B3B3B3;padding: 10px;margin: 0px 10px">
            <div>
              <div style="font-size: 14px;font-weight: 400;color:#3C3C3C;display: flex;align-items: center;">
                <div>{{ item.name }}</div>
                <div style="font-size: 10px;margin-left: 10px;background:#8AC4D0;padding:2px 5px;border-radius: 15px;color:white">{{ item.level }}</div>
              </div>
              <div style="position: absolute;top: 0px;right: 0px;bottom: 0px;width: 30px;display: flex;align-items: center">
                <van-icon name="https://rebeccamed.oss-cn-hangzhou.aliyuncs.com/resources/icons_list_edit.svg" size="15px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




  </div>
</template>

<script>
// @ is an alias to /src
import router from "@/router";

export default {
  name: 'HospitalList',
  mounted() {
    //获取医院列表
    let app = this;
    this.loading = true;
    this.axios({
      method: 'get',
      url:  this.$store.state.base_url+'/api/oab_client/hospital/list',
    }).then(function (res) {
      app.hospital_list = res.data.hospitals;
      app.loading = false;
    }).catch(function (error) {
    })
  },
  components: {
  },
  data() {
    return {
      loading:false,
      hospital_list:[],
    };
  },
  methods: {
    onClickLeft:function (){
      router.go(-1)
    },
    onClickRight:function (){
      router.push({ name: 'hospital_add'})
    },
    toHospital:function (e){
      console.log(e)
      router.push({ name: 'hospital_info', params: { id: e }})
    }
  }

}
</script>

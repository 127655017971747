<template>
  <div class="main">

    <div class="top-bar">
      <div class="card">
        <van-row>
          <van-col span="12">
            <div style="height:50px;display: flex;align-items: center">
              <div style="width: 40px;height: 40px;border-radius: 20px;border-radius: 20px;border: 2px solid #E5EFF8;display: flex;align-items: center;justify-content: center;overflow: hidden">
                <img src="https://rebeccamed.oss-cn-hangzhou.aliyuncs.com/resources/logo.jpg" style="width:100%" alt="">
              </div>
              <div style="margin-left: 10px;font-size: 20px;font-weight: 600;color:#828282"> {{ client_name }} </div>
            </div>
          </van-col>
          <van-col span="12">
            <div style="height:50px;display: flex;align-items: center;justify-content: flex-end" @click="to_setting">
              <img src="https://rebeccamed.oss-cn-hangzhou.aliyuncs.com/resources/icon_setting.png" style="width:20px" alt="">
            </div>
          </van-col>
        </van-row>
      </div>
    </div>

    <div style="padding: 10px">
      <div class="card">
        <div class="title">平台信息</div>
        <div style="margin-top: 10px;padding: 0px 20px">
          <van-row gutter="30">
            <van-col span="8">
              <div style="background: #F2F6F9;border-radius: 15px;padding: 10px" @click="to_url('patient_list')">
                <div style="height: 55px;display: flex;align-items: center;justify-content: center">
                  <div style="text-align: center;">
                    <img src="https://rebeccamed.oss-cn-hangzhou.aliyuncs.com/resources/icons_u.svg" style="width: 30px;" alt="">
                    <div style="color: #3C3C3C;font-size: 14px;font-weight: bold;margin-top: -5px">{{ user_number }}</div>
                  </div>
                </div>
              </div>
              <div class="text-center mini-title">患者人数</div>
            </van-col>
            <van-col span="8">
              <div style="background: #F2F6F9;border-radius: 15px;padding: 10px;height: 55px;display: flex;align-items: center;justify-content: center"  @click="to_url('hospital_list')">
                <div style="text-align: center">
                  <img src="https://rebeccamed.oss-cn-hangzhou.aliyuncs.com/resources/icons_h.svg" style="width: 30px;" alt="">
                </div>
              </div>
              <div class="text-center mini-title">医院列表</div>
            </van-col>
            <van-col span="8">
              <div style="background: #F2F6F9;border-radius: 15px;padding: 10px;height: 55px;display: flex;align-items: center;justify-content: center"  @click="to_url('diagnosis_list')">
                <div style="text-align: center">
                  <img src="https://rebeccamed.oss-cn-hangzhou.aliyuncs.com/resources/icons_d.svg" style="width: 30px;" alt="">
                </div>
              </div>
              <div class="text-center mini-title">诊断列表</div>
            </van-col>
          </van-row>
        </div>
      </div>
    </div>

    <div style="padding: 10px">
      <div class="card">
        <div class="title">我的操作</div>
        <div style="margin-top: 10px;padding: 0px 20px">
          <van-row gutter="30">
            <van-col span="8">
              <div style="background: #F2F6F9;border-radius: 15px;padding: 10px" @click="to_url('patient_list')">
                <div style="height: 55px;display: flex;align-items: center;justify-content: center">
                  <div style="text-align: center;">
                    <img src="https://rebeccamed.oss-cn-hangzhou.aliyuncs.com/resources/icons_edit.svg" style="width: 30px;" alt="">
                  </div>
                </div>
              </div>
              <div class="text-center mini-title">修改记录</div>
            </van-col>
            <van-col span="8"></van-col>
            <van-col span="8"></van-col>
          </van-row>
        </div>
      </div>
    </div>

    <div style="padding: 10px">
      <div class="card">
        <div class="title">常用功能</div>
        <div style="margin-top: 10px;padding: 0px 20px">
          <van-row gutter="30">
            <van-col span="8">
              <div style="background: #F2F6F9;border-radius: 15px;padding: 10px" @click="to_url('patient_add')">
                <div style="height: 40px;display: flex;align-items: center;justify-content: center">
                    <img src="https://rebeccamed.oss-cn-hangzhou.aliyuncs.com/resources/icons_add_u.svg" style="width: 30px;" alt="">
                </div>
              </div>
              <div class="text-center mini-title">添加患者</div>
            </van-col>
            <van-col span="8">
              <div style="background: #F2F6F9;border-radius: 15px;padding: 10px;height: 40px;display: flex;align-items: center;justify-content: center"  @click="to_url('hospital_add')">
                  <img src="https://rebeccamed.oss-cn-hangzhou.aliyuncs.com/resources/icons_add_h.svg" style="width: 30px;" alt="">
              </div>
              <div class="text-center mini-title">添加医院</div>
            </van-col>
            <van-col span="8">
              <div style="background: #F2F6F9;border-radius: 15px;padding: 10px;height: 40px;display: flex;align-items: center;justify-content: center"  @click="to_url('diagnosis_add')">
                  <img src="https://rebeccamed.oss-cn-hangzhou.aliyuncs.com/resources/icons_add_d.svg" style="width: 30px;" alt="">
              </div>
              <div class="text-center mini-title">添加诊断</div>
            </van-col>
          </van-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import router from "@/router";

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  mounted() {
    //获取医院列表
    let app = this;
    this.axios({
      method: 'get',
      url:  this.$store.state.base_url+'/api/oab_client/patient/list',
    }).then(function (res) {
      console.log(res)
      app.user_number = res.data.patients.length;
    }).catch(function (error) {
    })
  },
  data() {
    return {
      user_number:0,
      show: false,
      client_name: localStorage.getItem('client_name'),
    };
  },
  methods: {
    check_token:function (){
      localStorage
    },
    to_setting:function (){
      router.push({ name: 'Settings', params: { userId: 123 } })
    },
    add_patient:function (){
      router.push({ name: 'Settings', params: { userId: 123 } })
    },
    to_url:function (e) {
      router.push({ name: e})
    }
  }
}
</script>
<style>
.main{background:#E8F5FF}
.top-bar{padding:50px 10px 10px 10px}

.icons-area{width: 30px;height: 30px;background: rgba(0,0,0,0.1);display: flex;align-items: center;justify-content: center}
/*.card{width: 80%;height: 100px;background: rgba(0,0,0,0.1);border-radius: 10px}*/
</style>

<template>
  <div class="about">

    <van-nav-bar
        :title="page_title"
        @click-left="onClickLeft"
    >
      <template #left>
        <van-icon name="arrow-left" color="black"  size="20px"/>
      </template>
    </van-nav-bar>

    <div style="padding: 10px;margin-top: 20px">
      <div class="card">
        <div style="color: #828282;font-weight: 500;font-size: 16px;margin-left: 10px">患者姓名</div>

        <div style="color: #828282;font-size: 16px;font-weight: 500;margin: 10px">
          <input class="input-f" placeholder="请输入患者姓名" type="text" v-model="user_name">
        </div>

        <div style="margin-top:10px">
          <van-row gutter="10">
            <van-col span="12">
              <div style="color: #828282;font-size: 16px;font-weight: 500;padding-left: 10px">患者性别</div>
              <div style="color: #828282;font-size: 16px;font-weight: 500;margin: 10px">
                <input class="input-f" placeholder="请输入患者性别" type="text" v-model="user_sexy" readonly @click="showSexyPicker = true">
              </div>
            </van-col>
            <van-col span="12">
              <div style="color: #828282;font-size: 16px;font-weight: 500;padding-left: 10px">患者年龄</div>
              <div style="color: #828282;font-size: 16px;font-weight: 500;margin: 10px">
                <input class="input-f" placeholder="请输入患者出生年月" type="text" v-model="user_age" readonly  @click="showPickerTime = true">
              </div>
            </van-col>
          </van-row>
        </div>
        <div style="color: #828282;font-weight: 500;font-size: 16px;margin-left: 10px">患者联系电话</div>
        <div style="color: #828282;font-size: 16px;font-weight: 500;margin: 10px">
          <input class="input-f" placeholder="请输入" type="text" v-model="user_tel">
        </div>

        <div style="color: #828282;font-weight: 500;font-size: 16px;margin-left: 10px">患者所在医院</div>
        <div style="color: #828282;font-size: 16px;font-weight: 500;margin: 10px">
          <input class="input-f" placeholder="请输入" type="text" v-model="user_hospital" readonly @click="showPicker = true">
        </div>

      </div>


      <van-button block  color="#2A9EF8" style="margin-top:20px;box-shadow: 0px 10px 20px rgba(254, 84, 84, 0.2);border-radius: 10px;height: 50px" @click="onSubmit" :loading="uploading">
        <div style="font-size: 20px">提交</div>
      </van-button>


      <van-popup v-model:show="showPicker" position="bottom" get-container="body">
        <van-picker
            show-toolbar
            v-model="currentDate"
            :columns="hospital_list"
            @confirm="onConfirm"
            @cancel="showPicker = false"
        />
      </van-popup>

      <van-popup v-model:show="showSexyPicker" position="bottom" get-container="body">
        <van-picker
            show-toolbar
            v-model="currentDate"
            :columns="sexy_type"
            @confirm="onConfirmSexy"
            @cancel="showSexyPicker = false"
        />
      </van-popup>

      <van-popup v-model:show="showPickerTime" position="bottom" get-container="body">
        <van-datetime-picker
            type="date"
            @cancel="showPickerTime = false"
            @confirm="onConfirmBri"
            :min-date="minDate"
            :max-date="maxDate"
            title="患者出生年月"
        />
      </van-popup>

    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import router from "@/router";

export default {
  name: 'AddPatient',
  mounted() {
    let app = this;
    if (this.$route.params.id != undefined) {
      console.log(this.$route.params.id)
      //获取
      app.page_title = "编辑患者信息"
      this.axios({
        method: 'post',
        url:  this.$store.state.base_url+'/api/oab_client/patient/info',
        data:{id:this.$route.params.id}
      }).then(function (res) {
        app.user_id = app.$route.params.id;
        app.user_name = res.data.patient.name;
        app.user_sexy = res.data.patient.sexy;
        app.user_age = res.data.patient.org_bri;
        app.user_tel = res.data.patient.tel;
        app.user_hospital = res.data.patient.hospital.name;
      }).catch(function (error) {
      })
    }

    //获取医院名称列表
    this.axios({
      method: 'get',
      url:  this.$store.state.base_url+'/api/oab_client/hospital/list',
    }).then(function (res) {
      let arr = [];
      app.hospital_org_list = res.data.hospitals;
      res.data.hospitals.forEach(function (e){
        arr.push(e.name)
      })
      app.hospital_list = arr;
    }).catch(function (error) {
    })
  },
  data() {
    return {
      page_title:"添加患者",
      user_id:0,
      user_name:"",
      user_sexy:'',
      user_age: '',
      user_tel:'',
      user_hospital:'',
      select_hospital_index:0,
      hospital_org_list:[],
      hospital_list:[],
      sexy_type:['男性', '女性'],
      showSexyPicker: false,
      showPicker: false,
      showPickerTime: false,
      currentDate:new Date(1980, 0, 1),
      minDate:new Date(1900, 0, 1),
      maxDate:new Date(),
      uploading:false,
    };
  },
  methods: {
    onClickLeft:function (){
      router.go(-1)
    },
    onSubmit() {
      if (this.user_name == "") {
        this.$toast('请输入患者姓名');
        return 1;
      } else if (this.user_sexy == "") {
        this.$toast('请选择患者性别');
        return 1;
      } else if (this.user_age == "") {
        this.$toast('请输入患者出生年月');
        return 1;
      } else if (this.user_tel == "") {
        this.$toast('请输入患者联系电话');
        return 1;
      } else if (this.user_hospital == "") {
        this.$toast('请输入患者所在医院');
        return 1;
      }
      //提交数据
      let app = this;
      this.uploading = true
      this.axios({
        method: 'post',
        url:  this.$store.state.base_url+'/api/oab_client/patient/add',
        data:{
          id:app.user_id,
          user_name: app.user_name,
          user_sexy: app.user_sexy,
          user_age: app.user_age,
          user_tel: app.user_tel,
          user_hospital: app.user_hospital,
          hospital_id: app.hospital_org_list[app.select_hospital_index].id
        }
      }).then(function (res) {
        if (res.data.code == 200) {
          app.$toast(res.data.message);
          const timer = setInterval(() => {
            clearInterval(timer);
            router.go(-1);
          }, 2000);
        }
        app.uploading = false;
      }).catch(function (error) {
        app.uploading = false;
      })
    },
    onConfirmSexy:function (e){
      this.user_sexy = e;
      this.showSexyPicker = false;
    },
    onConfirm:function (e,index){
      this.select_hospital_index = index;
      this.user_hospital = e;
      this.showPicker = false;
    },
    onConfirmBri:function (e){
      console.log(e)
      this.showPickerTime = false;
      this.user_age = e.Format("yyyy-MM-dd");
    },
    onSelectHospital:function (e){
      this.user_hospital = e;
    }
  }

}
</script>

<style scoped>
.van-nav-bar__text{
  color: black !important;
}
.van-field{padding: 10px !important}
.input-f{background: #F0F5F9;color:#3C3C3C;border-radius: 5px;border:none;height:40px;font-size: 14px;width: 100%}
</style>

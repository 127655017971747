<template>
  <div class="about">

    <van-nav-bar
        title="设置"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
    >
      <template #left>
        <van-icon name="https://rebeccamed.oss-cn-hangzhou.aliyuncs.com/resources/icons_left_arrow.svg" size="18" />
      </template>
    </van-nav-bar>
    <div style="padding-top: 20%">
      <div style="display: flex;align-items: center;justify-content: center">
        <div style="padding: 20px;background: white;box-shadow: 0px 6px 12px rgba(167, 182, 194, 0.2);border-radius: 10px;">
          <img src="https://rebeccamed.oss-cn-hangzhou.aliyuncs.com/resources/logo_big.svg" style="width: 55px" alt="">
        </div>
      </div>
      <div style="text-align: center;margin-top: 20px">当前版本:1.0</div>
    </div>
    <div style="padding: 20px">
      <van-button color="#FD7D7D" type="primary" block @click="logout">退出登录</van-button>
    </div>



  </div>
</template>

<script>
// @ is an alias to /src
import router from "@/router";

export default {
  name: 'Settings',
  components: {
  },
  methods: {
    onClickLeft:function (){
      router.go(-1)
    },
    logout:function (){
      localStorage.removeItem('Authorization')
      router.push('/');
    }
  }

}
</script>

<style>
</style>

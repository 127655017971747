<template>
  <div class="about">

    <van-nav-bar
        title="编辑诊断信息"
        @click-left="onClickLeft"
    >
      <template #left>
        <van-icon name="arrow-left" color="black"  size="20px"/>
      </template>
    </van-nav-bar>

    <div v-if="loading == false">
      <div style="padding: 10px;margin-top:20px">
        <div class="card">
          <div style="color:#828282;font-size:16px">诊断</div>
          <div>
            <van-field
                v-model="diagnosis_content"
                rows="8"
                autosize
                type="textarea"
                placeholder="请输入.."
            />
          </div>
        </div>
      </div>
      <div style="margin-top:20px;padding:10px">

        <van-button block  color="#2A9EF8" style="box-shadow: 0px 10px 20px rgba(254, 84, 84, 0.2);border-radius: 10px;height: 50px" native-type="submit" @click="onSubmit">
          <div style="font-size: 20px">提交</div>
        </van-button>

      </div>
      <div style="margin: 10px">
        <van-button type="danger" block  color="#FD7D7D" style="box-shadow: 0px 10px 20px rgba(254, 84, 84, 0.2);border-radius: 10px;height: 50px" native-type="submit" @click="confirm_delete">
          <div style="font-size: 20px">删除</div>
        </van-button>
      </div>
    </div>
    <div v-else style="text-align: center;padding-top: 20%;font-weight: 800;font-size: 40px;color: rgba(0,0,0,0.1)">
      <van-loading size="40px" />
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import router from "@/router";
import { areaList } from '@vant/area-data';
import {Dialog} from "vant";

export default {
  name: 'DiagnosisInfo',
  components: {
  },
  data() {
    return {
      diagnosis_id:0,
      loading:true,
      diagnosis_content:"",
    };
  },
  mounted() {
    if (this.$route.params.id == undefined) {
      this.$toast('参数错误');
      const timer = setInterval(() => {
        clearInterval(timer);
        router.go(-1);
      }, 2000);
    } else {
      let app = this;
      this.axios({
        method: 'post',
        url:  this.$store.state.base_url+'/api/oab_client/diagnosis/info',
        data:{id:this.$route.params.id}
      }).then(function (res) {
        app.loading = false
        app.diagnosis_id = res.data.diagnosis.id;
        app.diagnosis_content = res.data.diagnosis.content;
      }).catch(function (error) {
      })
    }
  },
  methods: {
    onClickLeft:function (){
      router.go(-1)
    },
    confirm_delete:function (){
      let app = this;
      Dialog.confirm({
        title: '删除诊断信息',
        message: '是否确定删除该诊断信息',
      }).then(() => {
        app.axios({
          method: 'post',
          url:  this.$store.state.base_url+'/api/oab_client/diagnosis/delete',
          data:{
            id:app.diagnosis_id
          }
        }).then(function (res) {
          if (res.data.code == 200){
            router.go(-1);
          }
        }).catch(function (error) {});
      }).catch(() => {

      });
    },
    onSubmit() {
      let app = this;
      //检测数据
      if (this.diagnosis_content == "") {
        this.$toast('请输入诊断内容');
        return 1;
      }
      this.axios({
        method: 'post',
        url:  this.$store.state.base_url+'/api/oab_client/diagnosis/edit',
        data:{
          id: app.diagnosis_id,
          diagnosis_content: app.diagnosis_content
        }
      }).then(function (res) {
        if (res.data.code == 200) {
          console.log(12)
        }
        app.$toast("保存成功");
      }).catch(function (error) {
      })
    },
  }

}
</script>

<template>
  <div class="about">

    <van-nav-bar
        title="诊断列表"
        @click-left="onClickLeft"
        @click-right="onClickRight"
    >
      <template #left>
        <van-icon name="arrow-left" color="black"  size="20px"/>
      </template>
      <template #right>
        <van-icon name="plus" color="black"  size="20px"/>
      </template>
    </van-nav-bar>
    <div>
      <div v-if="loading == false">
        <div v-if="diagnosis_list.length == 0" style="text-align: center;padding-top: 20%;font-weight: 800;font-size: 40px;color: rgba(0,0,0,0.1)">
          暂无数据
        </div>
        <div v-else>
          <div v-for="item in diagnosis_list" @click="toDiagnosis(item.id)" style="position: relative;background:white;padding: 20px 10px;border-bottom: 1px solid #efefef">
            <div>
              <div style="font-size: 12px;font-weight: 800" v-html="item.content"></div>
              <div style="position: absolute;right: 0px;top: 0px;bottom: 0px;width: 30px;display: flex;align-items: center">
                <van-icon name="https://rebeccamed.oss-cn-hangzhou.aliyuncs.com/resources/icons_list_edit.svg" size="15px" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="text-align: center;padding-top: 20%;font-weight: 800;font-size: 40px;color: rgba(0,0,0,0.1)">
        <van-loading size="40px" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import router from "@/router";

export default {
  name: 'DiagnosisList',
  mounted() {
    //获取医院列表
    let app = this;
    this.axios({
      method: 'get',
      url:  this.$store.state.base_url+'/api/oab_client/diagnosis/list',
    }).then(function (res) {
      app.loading = false;
      app.diagnosis_list = res.data.diagnosis;
    }).catch(function (error) {
    })
  },
  components: {
  },
  data() {
    return {
      loading:true,
      diagnosis_list:[],
    };
  },
  methods: {
    onClickLeft:function (){
      router.go(-1)
    },
    onClickRight:function (){
      router.push({ name: 'diagnosis_add'})
    },
    toDiagnosis:function (e){
      console.log(e)
      router.push({ name: 'diagnosis_info', params: { id: e }})

    }
  }

}
</script>

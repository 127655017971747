<template>
  <div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>


    <!--    <router-view></router-view>-->



  </div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: 'app',
  components: {
    HelloWorld
  },
  data() {
    return {
      value:'111',
      show: false,
    };
  },
  methods: {
    showPopup() {
      this.show = true;
      console.log(this.show)
    },
  }
}
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #F0F2F8;
  min-height: 100vh;
}
.van-nav-bar__text{color: black !important;}
.card{background: #FFFFFF;box-shadow: 0px 6px 12px rgba(167, 182, 194, 0.2);border-radius: 10px;padding: 10px}
.main{}
.p-10{padding: 10px}
.m-t-30{margin-top: 30px}
.m-t-10{margin-top: 10px}
.text-center{text-align: center}
.title{font-weight: 600;font-size: 16px;color:#3C3C3C}
.mini-title{font-weight: 500;font-size: 14px;color:#828282;margin-top: 5px}
</style>

<template>
  <div class="login">


    <div style="padding-top: 20%">
      <div style="text-align: left;font-size: 20px;font-weight: 800;padding-left:20px">登录系统</div>
    </div>

    <div style="margin-top: 40px;padding:20px">
      <div class="login-card">

        <div class="login-title">请输入账号</div>
        <div style="margin-top: 10px">
          <input class="input-f" type="text" v-model="username">
        </div>

        <div class="login-title" style="margin-top: 20px;padding: 0px 10px">请输入密码</div>
        <div style="margin-top: 10px">
          <input class="input-f" type="password" v-model="password">
        </div>
      </div>
      <div style="margin-top: 30px">
        <van-button block type="primary" :loading="loading" native-type="submit" style="border-radius: 5px" @click="submit">
          提交
        </van-button>
      </div>

      <div style="position: fixed;bottom:30px;left: 0px;right: 0px;height: 60px;text-align: center">
        <img src="https://rebeccamed.oss-cn-hangzhou.aliyuncs.com/resources/full-logo.svg" style="width:134px" alt="">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import router from "@/router";

export default {
  name: 'Login',
  components: {
  },
  data() {
    return {
      username:"",
      password:"",
      loading:false,
    };
  },
  methods: {
    submit:function (){
      //提交密码
      let app = this;
      app.loading = true
      this.axios({
        method: 'post',
        url:  this.$store.state.base_url+'/api/oab_client/login',
        data:{
          email:this.username,
          password:this.password,
        }
      }).then(function (res) {
        app.loading = false;
        if (res.status == 200) {
          //成功登录
          console.log(res)
          let token = res.data.access_token;
          console.log(token)
          localStorage.setItem('Authorization', token)
          localStorage.setItem('client_name',  res.data.user_info.name);
          router.push('/')
        } else {
          app.$notify('账号密码错误');
        }
      }).catch(function (error) {
        console.log("111111")
      })
    }
  }
}
</script>
<style scoped>
.van-nav-bar__text{color: black !important;}
.van-field{padding: 10px !important}
.login{background:#E8F5FF;height: 100vh}
.login-card{background: #FFFFFF;box-shadow: 0px 6px 12px rgba(167, 182, 194, 0.2);border-radius: 10px;padding:20px}

.login-title {font-style: normal;font-weight: 500;font-size: 16px;line-height: 23px;display: flex;align-items: center;color: #828282}
.input-f{width: 100%;background: #F0F5F9;border-radius: 5px;border:none;height:40px}
</style>

<template>
  <div class="about">

    <van-nav-bar
        title="编辑医院信息"
        @click-left="onClickLeft"
    >
      <template #left>
        <van-icon name="arrow-left" color="black"  size="20px"/>
      </template>
    </van-nav-bar>

    <div v-if="!loading">
      <div  style="border-radius: 5px;overflow: hidden;margin-top: 20px">
        <van-form @submit="onSubmit">
          <div class="card" style="margin: 10px">
            <div style="color: #828282;font-size: 16px;font-weight: 500;padding-left: 10px">医院名称</div>
            <div style="color: #828282;font-size: 16px;font-weight: 500;margin: 10px">
              <input class="input-f" type="text" v-model="hospital_name">
            </div>
            <div>
              <van-row gutter="10">
                <van-col span="12">
                  <div style="color: #828282;font-size: 16px;font-weight: 500;padding-left: 10px">归宿城市</div>
                  <div style="color: #828282;font-size: 16px;font-weight: 500;margin: 10px">
                    <input class="input-f" type="text" v-model="hospital_city" readonly @click="showPickerCity = true">
                  </div>
                </van-col>

                <van-col span="12">
                  <div style="color: #828282;font-size: 16px;font-weight: 500;padding-left: 10px">医院级别</div>
                  <div style="color: #828282;font-size: 16px;font-weight: 500;margin: 10px">
                    <input class="input-f" type="text" v-model="hospital_level"  @click="showPicker = true">
                  </div>
                </van-col>
              </van-row>
            </div>
          </div>
          <div style="margin: 20px 10px 10px 10px">

            <van-button block  color="#2A9EF8" style="margin-top:20px;box-shadow: 0px 10px 20px rgba(254, 84, 84, 0.2);border-radius: 10px;height: 50px" native-type="submit">
              <div style="font-size: 20px">提交</div>
            </van-button>



          </div>
        </van-form>
        <div style="margin: 10px">

          <van-button  block   style="margin-top:20px;background: #FD7D7D;border-radius: 10px;height: 50px"  @click="confirm_delete">
            <div style="font-size: 20px;color:white">删除</div>
          </van-button>
        </div>
      </div>

      <van-popup v-model:show="showPicker" position="bottom" get-container="body">
        <van-picker
            show-toolbar
            :columns="hospital_level_all"
            @confirm="onConfirm"
            @cancel="showPicker = false"
        />
      </van-popup>

      <van-popup v-model:show="showPickerCity" position="bottom" get-container="body">
        <van-area
            title="请选择归属城市"
            :area-list="areaList"
            value="110101"
            @cancel="showPickerCity = false"
            @confirm="onConfirmCity"
        />
      </van-popup>
    </div>
    <div v-else style="padding-top: 20%;text-align: center">
      <van-loading size="40px" />
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import router from "@/router";
import { areaList } from '@vant/area-data';
import { Dialog } from 'vant';


export default {
  name: 'HospitalInfo',
  setup() {
    return { areaList };
  },
  components: {
  },
  mounted() {
    if (this.$route.params.id == undefined) {
      this.$toast('参数错误');
      const timer = setInterval(() => {
        clearInterval(timer);
        router.go(-1);
      }, 2000);
    } else {
      let app = this;
      this.loading = true;
      this.axios({
        method: 'post',
        url:  this.$store.state.base_url+'/api/oab_client/hospital/info',
        data:{id:this.$route.params.id}
      }).then(function (res) {
        app.loading = false;
        app.hospital_id = res.data.hospital.id;
        app.hospital_name = res.data.hospital.name;
        app.hospital_level = res.data.hospital.level;
        app.hospital_city = res.data.hospital.province + '-' + res.data.hospital.city + '-' + res.data.hospital.area;
      }).catch(function (error) {
      })
    }
  },
  data() {
    return {
      loading:false,
      hospital_id:"",
      hospital_name:"",
      hospital_city:'',
      hospital_level:'',
      hospital_level_all:["三级甲等","三级乙等","二级甲等","二级乙等","一级甲等","一级乙等"],
      showPicker: false,
      showPickerCity: false,
    };
  },
  methods: {
    onClickLeft:function (){
      router.go(-1);
    },
    confirm_delete:function (){
      let app = this;

      Dialog.confirm({
        title: '删除医院信息',
        message: '是否确定删除该家医院',
      }).then(() => {
        app.axios({
          method: 'post',
          url:  this.$store.state.base_url+'/api/oab_client/hospital/delete',
          data:{
            hospital_id:app.hospital_id
          }
        }).then(function (res) {
          if (res.data.code == 200){
            router.go(-1);
          }
        }).catch(function (error) {});
      }).catch(() => {

      });
    },
    onSubmit(values) {
      let app = this;
      //检测数据
      if (values.hospital_name == "") {
        this.$toast('请输入医院名称');
        return 1;
      } else if (values.hospital_level == "") {
        this.$toast('请输入医院级别');
        return 1;
      } else if (values.hospital_city == "") {
        this.$toast('请输入医院归属城市');
        return 1;
      } else if(app.hospital_id == '') {
        this.$toast('参数错误');
      }
      this.axios({
        method: 'post',
        url:  this.$store.state.base_url+'/api/oab_client/hospital/edit',
        data:{
          id: app.hospital_id,
          hospital_name: app.hospital_name,
          hospital_city: app.hospital_city,
          hospital_level: app.hospital_level
        }
      }).then(function (res) {
        if (res.data.code == 200) {
          console.log(12)
        }
        app.$toast("保存成功");
      }).catch(function (error) {

      })
    },
    onConfirm:function (e){
      this.hospital_level = e;
      this.showPicker = false;
    },
    onConfirmCity:function (e){
      this.hospital_city = e[0].name + '-' + e[1].name + '-' + e[2].name;
      this.showPickerCity = false;
    }
  }

}
</script>
<style scoped>
.van-field{padding: 10px !important}
.input-f{background: #F0F5F9;color:#3C3C3C;border-radius: 5px;border:none;height:40px;font-size: 14px;width: 100%}

</style>
<template>
  <div class="about" style="padding:60px 0px;">

    <div style="position: fixed;top: 0px;left: 0px;right: 0px;height: 44px;z-index: 999">
      <van-nav-bar
          title="资料信息查看"
          @click-left="onClickLeft"
      >
        <template #left>
          <van-icon name="arrow-left" color="black"  size="20px"/>
        </template>
      </van-nav-bar>
    </div>

    <div  v-if="patient != null">
      <div style="padding: 10px">
        <div class="card" style="position: relative">
          <div style="position: absolute;top: 0px;right: 0px;bottom: 0px;width: 45px;display: flex;align-items: center">
            <!-- <van-button type="primary" plain size="mini" color="black" @click="toPatientEdit">编辑</van-button> -->
          </div>
          <div style="font-size: 20px">
            <span style="margin-right: 5px">{{ patient.name }}</span> |
            <span style="margin-left: 5px">{{ patient.sexy }}</span> |
            <span style="margin-left: 5px">{{ patient.bri }}</span>
            <div style="font-size: 12px;margin-top: 5px">
              联系电话：{{ patient.tel }}
            </div>
          </div>
          <div style="font-size: 12px">
            <span>{{ patient.hospital.name }}</span> |
            <span style="margin-left: 5px">{{ patient.hospital.level }}</span>
          </div>
        </div>
      </div>

      <div style="padding: 0px 10px 10px 10px" v-if="!(diagnosis_content.length === 0)">
        <div class="card" style="position: relative">
          <div style="position: absolute;top: 5px;right: 10px">
            <!-- <van-button icon="plus" color="black" plain size="mini" @click="showDiagnosis = true">关联</van-button> -->
          </div>
          <div style="margin-bottom: 10px">诊断</div>
          <div v-for="content in diagnosis_content" style="font-size: 12px">
            <van-divider dashed />
            <div v-html="content"></div>
          </div>
        </div>
      </div>

      <div style="padding: 0px 10px 10px 10px" v-if="!(past_history.trim().length === 0)">
        <div class="card">
          <div>既往史</div>
          <div>
            <van-field
                v-model="past_history"
                rows="3"
                autosize
                type="textarea"
                placeholder="请输入既往史"
                readonly
            />
          </div>
        </div>
      </div>

      <div style="padding: 0px 10px 10px 10px" v-if="!(drug_therapy.trim().length === 0)">
        <div class="card">
          <div>药物治疗</div>
          <div>
            <van-field
                v-model="drug_therapy"
                rows="3"
                autosize
                type="textarea"
                placeholder="请输入药物治疗"
                readonly
            />
          </div>
        </div>
      </div>

      <div style="padding: 0px 10px 10px 10px" v-if="!( (micturition_day.trim().length === 0) && (micturition_night.trim().length === 0) && (micturition_24h.trim().length === 0))">
        <div class="card">
          <div>治疗前排尿</div>
          <div style="margin-top: 10px">
            <van-field v-model="micturition_day" label="白天" placeholder="请输入..."  v-if="!(micturition_day.trim().length === 0)" readonly/>
            <van-field v-model="micturition_night" label="晚上" placeholder="请输入..." v-if="!(micturition_night.trim().length === 0)" readonly/>
            <van-field v-model="micturition_24h" label="24H" placeholder="请输入..." v-if="!(micturition_24h.trim().length === 0)" readonly/>
          </div>
        </div>
      </div>

      <div style="padding: 0px 10px">
        <hr color="#d0d0d0"
        v-if="(treat_first.trim().length != 0) ||
        (one_week_micturition_day.trim().length != 0) ||
        (one_week_micturition_night.trim().length != 0) ||
        (one_week_micturition_24h.trim().length != 0) ||
        (one_week_feedback.trim().length != 0) ||
        (two_weeks_micturition_day.trim().length != 0) || 
        (two_weeks_micturition_night.trim().length != 0) || 
        (two_weeks_micturition_24h.trim().length != 0) ||
        (two_weeks_feedback.trim().length != 0) ||
        (three_weeks_micturition_day.trim().length != 0) || 
        (three_weeks_micturition_night.trim().length != 0) || 
        (three_weeks_micturition_24h.trim().length != 0) ||
        (three_weeks_feedback.trim().length != 0)">
      </div>

      <div style="padding: 10px" v-if="!(treat_first.trim().length === 0)">
        <div class="card">
          <van-field v-model="treat_first"
                     readonly
                     clickable
                     label="首次治疗时间"
                     placeholder="选择日期"
          />
        </div>
      </div>
      <div style="padding: 0px 10px 10px 10px" v-if="!( (one_week_micturition_day.trim().length === 0) && (one_week_micturition_night.trim().length === 0) && (one_week_micturition_24h.trim().length === 0))">
        <div class="card">
          <div>1周排尿</div>
          <div style="margin-top: 10px">
            <van-field v-model="one_week_micturition_day" label="白天" placeholder="请输入..." v-if="!(one_week_micturition_day.trim().length === 0)" readonly/>
            <van-field v-model="one_week_micturition_night" label="晚上" placeholder="请输入..." v-if="!(one_week_micturition_night.trim().length === 0)" readonly/>
            <van-field v-model="one_week_micturition_24h" label="24H" placeholder="请输入..." v-if="!(one_week_micturition_24h.trim().length === 0)" readonly/>
          </div>
        </div>
      </div>

      <div style="padding: 0px 10px 10px 10px" v-if="!(one_week_feedback.trim().length === 0)">
        <div class="card">
          <div>1周反馈</div>
          <div>
            <van-field
                v-model="one_week_feedback"
                rows="3"
                autosize
                type="textarea"
                placeholder="请输入.."
                readonly
            />
          </div>
        </div>
      </div>

      <div style="padding: 0px 10px 10px 10px" v-if="!( (two_weeks_micturition_day.trim().length === 0) && (two_weeks_micturition_night.trim().length === 0) && (two_weeks_micturition_24h.trim().length === 0) )">
        <div class="card">
          <div>2周排尿</div>
          <div style="margin-top: 10px">
            <van-field v-model="two_weeks_micturition_day" label="白天" placeholder="请输入..." v-if="!(two_weeks_micturition_day.trim().length === 0)" readonly/>
            <van-field v-model="two_weeks_micturition_night" label="晚上" placeholder="请输入..." v-if="!(two_weeks_micturition_night.trim().length === 0)" readonly/>
            <van-field v-model="two_weeks_micturition_24h" label="24H" placeholder="请输入..." v-if="!(two_weeks_micturition_24h.trim().length === 0)" readonly/>
          </div>
        </div>
      </div>

      <div style="padding: 0px 10px 10px 10px" v-if="!(two_weeks_feedback.trim().length === 0)">
        <div class="card">
          <div>2周反馈</div>
          <div>
            <van-field
                v-model="two_weeks_feedback"
                rows="3"
                autosize
                type="textarea"
                placeholder="请输入.."
                readonly
            />
          </div>
        </div>
      </div>

      <div style="padding: 0px 10px 10px 10px" v-if="!( (three_weeks_micturition_day.trim().length === 0) && (three_weeks_micturition_night.trim().length === 0) && (three_weeks_micturition_24h.trim().length === 0) )">
        <div class="card">
          <div>3周排尿</div>
          <div style="margin-top: 10px">
            <van-field v-model="three_weeks_micturition_day" label="白天" placeholder="请输入..." v-if="!(three_weeks_micturition_day.trim().length === 0)" readonly/>
            <van-field v-model="three_weeks_micturition_night" label="晚上" placeholder="请输入..." v-if="!(three_weeks_micturition_night.trim().length === 0)" readonly/>
            <van-field v-model="three_weeks_micturition_24h" label="24H" placeholder="请输入..." v-if="!(three_weeks_micturition_24h.trim().length === 0)" readonly/>
          </div>
        </div>
      </div>

      <div style="padding: 0px 10px 10px 10px" v-if="!(three_weeks_feedback.trim().length === 0)">
        <div class="card">
          <div>3周反馈</div>
          <div>
            <van-field
                v-model="three_weeks_feedback"
                rows="3"
                autosize
                type="textarea"
                placeholder="请输入.."
                readonly
            />
          </div>
        </div>
      </div>

      <div style="padding: 0px 10px"
      v-if="(treat_second.trim().length != 0) ||
            (treat_second_one_week_micturition_day.trim().length != 0) || 
            (treat_second_one_week_micturition_night.trim().length != 0) || 
            (treat_second_one_week_micturition_24h.trim().length != 0) ||
            (treat_second_two_weeks_micturition_day.trim().length != 0) || 
            (treat_second_two_weeks_micturition_night.trim().length != 0) || 
            (treat_second_two_weeks_micturition_24h.trim().length != 0) ||
            (treat_second_two_weeks_feedback.trim().length != 0) ||
            (treat_second_three_weeks_micturition_day.trim().length != 0) || 
            (treat_second_three_weeks_micturition_night.trim().length != 0) || 
            (treat_second_three_weeks_micturition_24h.trim().length != 0) ||
            (treat_second_three_weeks_feedback.trim().length != 0)">
        <hr color="#d0d0d0">
      </div>

      <div style="padding: 10px" v-if="!(treat_second.trim().length === 0)">
        <div class="card">
          <van-field v-model="treat_second"
                     readonly
                     clickable
                     label="2次治疗时间"
                     placeholder="选择日期"
          />
        </div>
      </div>
      <div style="padding: 0px 10px 10px 10px" v-if="!( (treat_second_one_week_micturition_day.trim().length === 0) && (treat_second_one_week_micturition_night.trim().length === 0) && (treat_second_one_week_micturition_24h.trim().length === 0) )">
        <div class="card">
          <div>1周排尿</div>
          <div style="margin-top: 10px">
            <van-field v-model="treat_second_one_week_micturition_day" label="白天" placeholder="请输入..." v-if="!(treat_second_one_week_micturition_day.trim().length === 0)" readonly/>
            <van-field v-model="treat_second_one_week_micturition_night" label="晚上" placeholder="请输入..." v-if="!(treat_second_one_week_micturition_night.trim().length === 0)" readonly/>
            <van-field v-model="treat_second_one_week_micturition_24h" label="24H" placeholder="请输入..." v-if="!(treat_second_one_week_micturition_24h.trim().length === 0)" readonly/>
          </div>
        </div>
      </div>

      <div style="padding: 0px 10px 10px 10px" v-if="!(treat_second_one_week_feedback.trim().length === 0)">
        <div class="card">
          <div>1周反馈</div>
          <div>
            <van-field
                v-model="treat_second_one_week_feedback" 
                readonly
                rows="3"
                autosize
                type="textarea"
                placeholder="请输入.."
            />
          </div>
        </div>
      </div>

      <div style="padding: 0px 10px 10px 10px" v-if="!( (treat_second_two_weeks_micturition_day.trim().length === 0) && (treat_second_two_weeks_micturition_night.trim().length === 0) && (treat_second_two_weeks_micturition_24h.trim().length === 0) )">
        <div class="card">
          <div>2周排尿</div>
          <div style="margin-top: 10px">
            <van-field v-model="treat_second_two_weeks_micturition_day" label="白天" placeholder="请输入..." v-if="!(treat_second_two_weeks_micturition_day.trim().length === 0)" readonly/>
            <van-field v-model="treat_second_two_weeks_micturition_night" label="晚上" placeholder="请输入..." v-if="!(treat_second_two_weeks_micturition_night.trim().length === 0)" readonly/>
            <van-field v-model="treat_second_two_weeks_micturition_24h" label="24H" placeholder="请输入..." v-if="!(treat_second_two_weeks_micturition_24h.trim().length === 0)" readonly/>
          </div>
        </div>
      </div>

      <div style="padding: 0px 10px 10px 10px" v-if="!(treat_second_two_weeks_feedback.trim().length === 0)">
        <div class="card">
          <div>2周反馈</div>
          <div>
            <van-field
                v-model="treat_second_two_weeks_feedback" 
                readonly
                rows="3"
                autosize
                type="textarea"
                placeholder="请输入.."
            />
          </div>
        </div>
      </div>

      <div style="padding: 0px 10px 10px 10px" v-if="!( (treat_second_three_weeks_micturition_day.trim().length === 0) && (treat_second_three_weeks_micturition_night.trim().length === 0) && (treat_second_three_weeks_micturition_24h.trim().length === 0) )">
        <div class="card">
          <div>3周排尿</div>
          <div style="margin-top: 10px">
            <van-field v-model="treat_second_three_weeks_micturition_day" label="白天"  placeholder="请输入..." v-if="!(treat_second_three_weeks_micturition_day.trim().length === 0)" readonly/>
            <van-field v-model="treat_second_three_weeks_micturition_night" label="晚上" placeholder="请输入..." v-if="!(treat_second_three_weeks_micturition_night.trim().length === 0)" readonly/>
            <van-field v-model="treat_second_three_weeks_micturition_24h" label="24H" placeholder="请输入..." v-if="!(treat_second_three_weeks_micturition_24h.trim().length === 0)" readonly/>
          </div>
        </div>
      </div>

      <div style="padding: 0px 10px 10px 10px" v-if="!(treat_second_three_weeks_feedback.trim().length === 0)">
        <div class="card">
          <div>3周反馈</div>
          <div>
            <van-field
                v-model="treat_second_three_weeks_feedback" 
                readonly
                rows="3"
                autosize
                type="textarea"
                placeholder="请输入.."
            />
          </div>
        </div>
      </div>

      <div style="padding: 0px 10px"
      v-if="(urine_protein.trim().length != 0) || (abnormal_index.trim().length != 0)">
        <hr color="#d0d0d0">
      </div>

      <div style="padding: 0px 10px 10px 10px" v-if="!(urine_protein.trim().length === 0)" >
        <div class="card">
          <div>尿蛋白</div>
          <div>
            <van-field
                v-model="urine_protein"
                readonly
                rows="3"
                autosize
                type="textarea"
                placeholder="请输入.."
            />
          </div>
        </div>
      </div>

      <div style="padding: 0px 10px 10px 10px" v-if="!(abnormal_index.trim().length === 0)">
        <div class="card">
          <div>异常指标</div>
          <div>
            <van-field
                v-model="abnormal_index" 
                readonly
                rows="3"
                autosize
                type="textarea"
                placeholder="请输入.."
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else style="padding-top: 20%;text-align: center">
      <van-loading size="40px" />
    </div>

    <div style="position: fixed;bottom: 0px;left: 0px;right: 0px;height: 44px;padding: 10px">
      <van-button plain type="primary" block @click="toUrinationLog">排尿日志</van-button>
    </div>

    <van-calendar
        v-model:show="showPicker"
        :min-date="minDate"
        :max-date="maxDate"
        :show-confirm="false"
        @confirm="onConfirmTreatFirst"
    />


    <van-popup
        v-model:show="showDiagnosis"
        position="bottom"
        :style="{ height: '80%' }"
    >
      <div style="padding:10px">
        <van-checkbox-group v-model="select_diagnosis">
          <div v-for="item in diagnosis" style="border-bottom: 1px solid #e8e8e8" @click="clickDiagnosis">
            <van-checkbox  :name="item.id">
              <div style="margin: 10px;width: 95vw" v-html="item.content"></div>
            </van-checkbox>
          </div>
        </van-checkbox-group>

        <div style="position: fixed;bottom: 10px;left: 10px;right: 10px;height: 50px">
          <van-button plain hairline block color="black" @click="selectDiagnosis">确定</van-button>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
// @ is an alias to /src
import router from "@/router";

export default {
  name: 'PatientInfoReadOnly',
  components: {
  },
  data() {
    return {
      showDiagnosis:false,
      select_diagnosis:[],
      diagnosis:[],
      diagnosis_content:[],
      showPicker:false,
      minDate:new Date(2010, 0, 1),
      maxDate:new Date(),
      patient:null,
      past_history:'',
      drug_therapy:'',
      micturition_day:'',
      micturition_night:'',
      micturition_24h:'',

      treat_order:1,
      treat_first:'',

      one_week_micturition_day:'',
      one_week_micturition_night:'',
      one_week_micturition_24h:'',
      one_week_feedback:'',

      two_weeks_micturition_day:'',
      two_weeks_micturition_night:'',
      two_weeks_micturition_24h:'',
      two_weeks_feedback:'',


      three_weeks_micturition_day:'',
      three_weeks_micturition_night:'',
      three_weeks_micturition_24h:'',
      three_weeks_feedback:'',

      treat_second:'',
      treat_second_one_week_micturition_day:'',
      treat_second_one_week_micturition_night:'',
      treat_second_one_week_micturition_24h:'',
      treat_second_one_week_feedback:'',


      treat_second_two_weeks_micturition_day:'',
      treat_second_two_weeks_micturition_night:'',
      treat_second_two_weeks_micturition_24h:'',
      treat_second_two_weeks_feedback:'',

      treat_second_three_weeks_micturition_day:'',
      treat_second_three_weeks_micturition_night:'',
      treat_second_three_weeks_micturition_24h:'',
      treat_second_three_weeks_feedback:'',

      urine_protein:'',
      abnormal_index:'',

    };
  },
  watch: {
    select_diagnosis(new_value) {
      console.log(new_value)
      let content = [], app = this, arr = Object.values(new_value);
      console.log(this.diagnosis);
      arr.forEach(function (e){
        app.diagnosis.forEach(function (co){
          if (co.id == e ){
            content.push(co.content)
          }
        })
      });
      app.diagnosis_content = content;
    }
  },
  mounted() {
    if (this.$route.params.id == undefined) {
      this.$toast('参数错误');
      const timer = setInterval(() => {
        clearInterval(timer);
        router.go(-1);
      }, 2000);
    } else {
      let app = this;
      this.axios({
        method: 'get',
        url:  this.$store.state.base_url+'/api/oab_client/diagnosis/list',
      }).then(function (res) {
        app.diagnosis = res.data.diagnosis;
      }).catch(function (error) {
      })

      this.axios({
        method: 'post',
        url:  this.$store.state.base_url+'/api/oab_client/patient/info',
        data:{id:this.$route.params.id}
      }).then(function (res) {
        app.patient = res.data.patient;
        app.diagnosis_content = res.data.patient.info.diagnosis_content;

        //赋值
        app.past_history = app.stringF(res.data.patient.info.past_history);
        app.drug_therapy = app.stringF(res.data.patient.info.drug_therapy);
        app.micturition_day = app.stringF(res.data.patient.info.micturition_day);
        app.micturition_night = app.stringF(res.data.patient.info.micturition_night);
        app.micturition_24h = app.stringF(res.data.patient.info.micturition_24h);

        app.treat_first = app.stringF(res.data.patient.info.treat_first);

        app.one_week_micturition_day = app.stringF(res.data.patient.info.one_week_micturition_day);
        app.one_week_micturition_night = app.stringF(res.data.patient.info.one_week_micturition_night);
        app.one_week_micturition_24h = app.stringF(res.data.patient.info.one_week_micturition_24h);
        app.one_week_feedback = app.stringF(res.data.patient.info.one_week_feedback);

        app.two_weeks_micturition_day = app.stringF(res.data.patient.info.two_weeks_micturition_day);
        app.two_weeks_micturition_night = app.stringF(res.data.patient.info.two_weeks_micturition_night);
        app.two_weeks_micturition_24h = app.stringF(res.data.patient.info.two_weeks_micturition_24h);
        app.two_weeks_feedback = app.stringF(res.data.patient.info.two_weeks_feedback);

        app.three_weeks_micturition_day = app.stringF(res.data.patient.info.three_weeks_micturition_day);
        app.three_weeks_micturition_night = app.stringF(res.data.patient.info.three_weeks_micturition_night);
        app.three_weeks_micturition_24h = app.stringF(res.data.patient.info.three_weeks_micturition_24h);
        app.three_weeks_feedback = app.stringF(res.data.patient.info.three_weeks_feedback);

        app.treat_second = app.stringF(res.data.patient.info.treat_second);
        app.treat_second_one_week_micturition_day = app.stringF(res.data.patient.info.treat_second_one_week_micturition_day);
        app.treat_second_one_week_micturition_night = app.stringF(res.data.patient.info.treat_second_one_week_micturition_night);
        app.treat_second_one_week_micturition_24h = app.stringF(res.data.patient.info.treat_second_one_week_micturition_24h);
        app.treat_second_one_week_feedback = app.stringF(res.data.patient.info.treat_second_one_week_feedback);


        app.treat_second_two_weeks_micturition_day = app.stringF(res.data.patient.info.treat_second_two_weeks_micturition_day);
        app.treat_second_two_weeks_micturition_night = app.stringF(res.data.patient.info.treat_second_two_weeks_micturition_night);
        app.treat_second_two_weeks_micturition_24h = app.stringF(res.data.patient.info.treat_second_two_weeks_micturition_24h);
        app.treat_second_two_weeks_feedback = app.stringF(res.data.patient.info.treat_second_two_weeks_feedback);

        app.treat_second_three_weeks_micturition_day = app.stringF(res.data.patient.info.treat_second_three_weeks_micturition_day);
        app.treat_second_three_weeks_micturition_night = app.stringF(res.data.patient.info.treat_second_three_weeks_micturition_night);
        app.treat_second_three_weeks_micturition_24h = app.stringF(res.data.patient.info.treat_second_three_weeks_micturition_24h);
        app.treat_second_three_weeks_feedback = app.stringF(res.data.patient.info.treat_second_three_weeks_feedback);


        app.urine_protein = app.stringF(res.data.patient.info.urine_protein);
        app.abnormal_index = app.stringF(res.data.patient.info.abnormal_index);


      }).catch(function (error) {
      });

    }
  },
  methods: {
    isStringsEmpty:function(obj){
          // return (obj.value.length === 0) ? true : false;
          console.log(obj.value)
    },
    toUrinationLog(){
      router.push({ name: 'uri_list', params: { id: this.patient.id }});
    },
    stringF:function (e){
      if (e == null || e == undefined) {
        return "";
      }else {
        return e
      }
    },
    clickDiagnosis:function (){
      console.log(this.select_diagnosis)
    },
    onClickLeft:function (){
      router.go(-1)
    },
    toPatientEdit:function (){
      router.push({ name: 'patient_edit', params: { id: this.patient.id }})
    },
    selectDiagnosis:function (){
      this.showDiagnosis = false;
      console.log(this.diagnosis_content);
    },
    treatOrder:function (e) {
      this.treat_order = e;
      this.showPicker = true;
      console.log(this.treat_order);
    },
    onConfirmTreatFirst:function (e) {
      let time = e.Format("yyyy-MM-dd");
      if (this.treat_order == 1) {
        this.treat_first = time;
      } else if(this.treat_order == 2) {
        this.treat_second = time;
      }
      this.showPicker = false;
    },
    onSubmit:function (){
      //保存数据
      let app = this;
      this.axios({
        method: 'post',
        url:  this.$store.state.base_url+'/api/oab_client/patient_info/save',
        data:{
          patient_id: this.patient.id,
          diagnosis_content: this.diagnosis_content,
          past_history: this.past_history,
          drug_therapy: this.drug_therapy,
          micturition_day: this.micturition_day,
          micturition_night: this.micturition_night,
          micturition_24h: this.micturition_24h,

          treat_first: this.treat_first,

          one_week_micturition_day: this.one_week_micturition_day,
          one_week_micturition_night: this.one_week_micturition_night,
          one_week_micturition_24h: this.one_week_micturition_24h,
          one_week_feedback: this.one_week_feedback,

          two_weeks_micturition_day: this.two_weeks_micturition_day,
          two_weeks_micturition_night: this.two_weeks_micturition_night,
          two_weeks_micturition_24h: this.two_weeks_micturition_24h,
          two_weeks_feedback: this.two_weeks_feedback,

          three_weeks_micturition_day: this.three_weeks_micturition_day,
          three_weeks_micturition_night: this.three_weeks_micturition_night,
          three_weeks_micturition_24h: this.three_weeks_micturition_24h,
          three_weeks_feedback: this.three_weeks_feedback,

          treat_second: this.treat_second,
          treat_second_one_week_micturition_day: this.treat_second_one_week_micturition_day,
          treat_second_one_week_micturition_night: this.treat_second_one_week_micturition_night,
          treat_second_one_week_micturition_24h: this.treat_second_one_week_micturition_24h,
          treat_second_one_week_feedback: this.treat_second_one_week_feedback,


          treat_second_two_weeks_micturition_day: this.treat_second_two_weeks_micturition_day,
          treat_second_two_weeks_micturition_night: this.treat_second_two_weeks_micturition_night,
          treat_second_two_weeks_micturition_24h: this.treat_second_two_weeks_micturition_24h,
          treat_second_two_weeks_feedback: this.treat_second_two_weeks_feedback,

          treat_second_three_weeks_micturition_day: this.treat_second_three_weeks_micturition_day,
          treat_second_three_weeks_micturition_night: this.treat_second_three_weeks_micturition_night,
          treat_second_three_weeks_micturition_24h: this.treat_second_three_weeks_micturition_24h,
          treat_second_three_weeks_feedback: this.treat_second_three_weeks_feedback,

          urine_protein: this.urine_protein,
          abnormal_index: this.abnormal_index,

        }
      }).then(function (res) {
        if (res.data.code == 200) {
          app.$toast(res.data.message);
        }
      }).catch(function (error) {


      });
    }
  }

}
</script>

<style>
.van-field{padding: 5px 0px !important}
</style>
